// Generated by Framer (c815cea)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Navigation from "https://framerusercontent.com/modules/53rsUR62p58lGXLsHKfU/O9fUNkkow2zjqItiiOKl/xiJyPzjU1.js";
const NavigationFonts = getFonts(Navigation);

const cycleOrder = ["mg5AEcqWL"];

const serializationHash = "framer-16LdN"

const variantClassNames = {mg5AEcqWL: "framer-v-lannq2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "mg5AEcqWL", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-lannq2", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"mg5AEcqWL"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-1fzdufa-container"} layoutDependency={layoutDependency} layoutId={"Ffw9mi6k2-container"} transformTemplate={transformTemplate1}><Navigation height={"100%"} id={"Ffw9mi6k2"} layoutId={"Ffw9mi6k2"} style={{width: "100%"}} variant={"FisGvG1zv"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-16LdN.framer-nw89fa, .framer-16LdN .framer-nw89fa { display: block; }", ".framer-16LdN.framer-lannq2 { height: 76px; overflow: hidden; position: relative; width: 200px; }", ".framer-16LdN .framer-1fzdufa-container { flex: none; height: auto; left: 50%; position: absolute; top: 50%; width: 1140px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 76
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerEXuUYol37: React.ComponentType<Props> = withCSS(Component, css, "framer-16LdN") as typeof Component;
export default FramerEXuUYol37;

FramerEXuUYol37.displayName = "Navigation";

FramerEXuUYol37.defaultProps = {height: 76, width: 200};

addFonts(FramerEXuUYol37, [{explicitInter: true, fonts: []}, ...NavigationFonts], {supportsExplicitInterCodegen: true})